// ProfileCard.js
import React from 'react';

const ProfileCard = ({ profile }) => {
    return (
        <div className="p-6 bg-white shadow-lg rounded-lg">
            <h2 className="text-lg font-semibold text-gray-900 mb-4">Client Profile</h2>
            <ul>
                <li>
                    <strong>sweetness:</strong> {profile.sweetness}
                </li>
                <li>
                    <strong>acidity:</strong> {profile.acidity}
                </li>
                <li>
                    <strong>bitterness:</strong> {profile.bitterness}
                </li>
                <li>
                    <strong>body:</strong> {profile.body}
                </li>
            {profile.tasteNotes && (
                <li>
                    <strong>Taste Notes:</strong> {profile.tasteNotes.join(', ')}
                </li>
            )}
            <p><strong>drinkingType:</strong> {profile.drinkingType}</p>
            </ul>
        </div>
    );
};

export default ProfileCard;
