import React from "react";

export const SuggestionCard = ({blend, rule,secondRule,  index}) => (

        <div
        className="p-4 bg-gray-50 rounded-lg border border-gray-200 shadow-sm w-full mt-4">
        <h3 className="text-lg font-semibold text-gray-900">Blend Option {index + 1}:</h3>
        <p>Roast Level: {blend.roastLevel}</p>
        <p>Rule: {rule}</p>
        {secondRule && <p>Second Rule: {secondRule}</p>}
        <div className="mt-2">
            {Object.keys(blend.blendArray).map(coffeeType => (
                blend.blendArray[coffeeType] > 0 && (
                    <p key={coffeeType} className="text-sm font-medium capitalize">
                        {coffeeType}: <span
                        className="font-normal">{blend.blendArray[coffeeType]}%</span>
                    </p>
                )
            ))}
        </div>
        <p className="text-sm font-medium">Taste Notes: <span
            className="font-normal">{blend.tasteNotes.length > 0 ? blend.tasteNotes.join(', ') : "None"}</span>
        </p>

        {blend.matchPercentage !== undefined && (
            <>
                <p className="text-sm font-medium">Match Percentage: <span
                    className="font-normal">{blend.matchPercentage.toFixed(2)}%</span></p>
                <p className="text-sm font-medium">Rules Met: <span
                    className="font-normal">{blend.rulesMet.length > 0 ? blend.rulesMet.join(', ') : "None"}</span>
                </p>
            </>
        )}
    </div>
);