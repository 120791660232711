import React from "react";


const Test = () => {


     const coffeeTypes = [
        {
            roastLevel: 1,
            ethiopia: {
                acidity: 4, sweetness: 3, bitterness: 1, body: 2,
                tasteNotes: {
                    "fruity and citrus": ["לימון", "אוכמניות"],
                    "spices and roasted": ["צפורן", "קינמון"],
                    "sweet and floral": ["דבש", "פרח יסמין"]
                }
            },
            brazil: {
                acidity: 1, sweetness: 3, bitterness: 2, body: 2,
                tasteNotes: {
                    "chocolaty and nuts": ["חמאה חומה", "אגוזי לוז"],
                    "sweet and floral": ["וניל"]
                }
            },
            colombia: {
                acidity: 2, sweetness: 5, bitterness: 1, body: 3,
                tasteNotes: {
                    "fruity and citrus": ["מנגו", "אשכולית"],
                    "chocolaty and nuts": ["קקאו"],
                    "sweet and floral": ["סילאן"]
                }
            },
            honduras: {
                acidity: 2, sweetness: 3, bitterness: 2, body: 2,
                tasteNotes: {
                    "chocolaty and nuts": ["בוטנים"],
                    "spices and roasted": ["תבלינים", "תה"]
                }
            },
            papua: {
                acidity: 3, sweetness: 5, bitterness: 1, body: 3,
                tasteNotes: {
                    "fruity and citrus": ["תפוז", "פירות טרופיים"],
                    "sweet and floral": ["היביסקוס"]
                }
            },
            robusta: {
                acidity: 1, sweetness: 1, bitterness: 4, body: 4,
                tasteNotes: {
                    "spices and roasted": ["שקדים קלויים", "עשבי תיבול"]
                }
            }
        },
        {
            roastLevel: 2,
            ethiopia: {
                acidity: 3, sweetness: 5, bitterness: 1, body: 3,
                tasteNotes: {
                    "fruity and citrus": ["תפוח"],
                    "spices and roasted": ["תה"]
                }
            },
            brazil: {
                acidity: 1, sweetness: 4, bitterness: 2, body: 3,
                tasteNotes: {
                    "chocolaty and nuts": ["שוקולד חלב", "שקד"],
                    "sweet and floral": ["כרמל"]
                }
            },
            colombia: {
                acidity: 3, sweetness: 3, bitterness: 2, body: 5,
                tasteNotes: {
                    "fruity and citrus": ["פירות טרופיים"],
                    "chocolaty and nuts": ["קקאו"],
                    "sweet and floral": ["דבש"]
                }
            },
            honduras: {
                acidity: 2, sweetness: 3, bitterness: 3, body: 3,
                tasteNotes: {
                    "chocolaty and nuts": ["שוקולד מריר"],
                    "spices and roasted": ["תבלינים"],
                    "sweet and floral": ["סוכר חום"]
                }
            },
            papua: {
                acidity: 3, sweetness: 4, bitterness: 3, body: 3,
                tasteNotes: {
                    "fruity and citrus": ["תפוז"],
                    "sweet and floral": ["סילאן"]
                }
            },
            robusta: {
                acidity: 1, sweetness: 2, bitterness: 5, body: 5,
                tasteNotes: {
                    "spices and roasted": ["צפורן", "קנה סוכר"]
                }
            }
        },
        {
            roastLevel: 3,
            ethiopia: {
                acidity: 1, sweetness: 4, bitterness: 3, body: 3,
                tasteNotes: {
                    "spices and roasted": ["קינמון"],
                    "sweet and floral": ["היביסקוס"]
                }
            },
            brazil: {
                acidity: 1, sweetness: 3, bitterness: 3, body: 3,
                tasteNotes: {
                    "chocolaty and nuts": ["שוקולד מריר"],
                    "spices and roasted": ["שקדים קלויים"]
                }
            },
            colombia: {
                acidity: 1, sweetness: 2, bitterness: 4, body: 2,
                tasteNotes: {
                    "fruity and citrus": ["אשכולית"],
                    "spices and roasted": ["צפורן"],
                    "chocolaty and nuts": ["קקאו"]
                }
            },
            honduras: {
                acidity: 1, sweetness: 2, bitterness: 3, body: 5,
                tasteNotes: {
                    "spices and roasted": ["עשבי תיבול"],
                    "sweet and floral": ["סילאן"]
                }
            },
            papua: {
                acidity: 1, sweetness: 3, bitterness: 4, body: 4,
                tasteNotes: {
                    "fruity and citrus": ["לימון"],
                    "sweet and floral": ["וניל"]
                }
            },
            robusta: {
                acidity: 0, sweetness: 0, bitterness: 0, body: 0,
                tasteNotes: {}
            }
        }
    ];


    let find = coffeeTypes.find(type => type.roastLevel === 1);
    let tasteNotes = find.colombia.tasteNotes;

    Object.keys(find.colombia)

    console.log("coffee types: ",coffeeTypes)
    console.log("find: ",find)
    console.log("tasteNotes: ",tasteNotes)
    console.log("keys: ",Object.keys(find.colombia.tasteNotes))


    return(
        <>

        </>
    )
}

export default Test

