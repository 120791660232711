import { Route, Routes} from "react-router-dom";
import './App.css';
import CoffeeBlender from "./CoffeeBlender/CoffeeBlender";
import Editor from "./Editor/Editor";
import React from "react";
import Test from "./UI/Test";


function App() {
  return (
  <>
      <Routes>
        <Route path="/" element={<CoffeeBlender/>}/>
        <Route path="/test" element={<Test/>}/>
        <Route path="/editor" element={<Editor />}/>
      </Routes>


  </>
  );
}

export default App;
