import {
	calculateBlendsByProfile,
} from "../utils/functionsUtils";


export function calculateBlendsSortAndExclude(
    clientProfile,
    coffeeTypesTable,
    excludedCoffees,
    preset
) {
    let blends = [];

    coffeeTypesTable.forEach((type) => {
        blends.push(...calculateBlendsByProfile(clientProfile, type,preset));
    });


    return filterAndSort(blends, excludedCoffees, preset);
}


export function filterAndSort(blends, excludedCoffees, preset) {
    let filteredBlends = blends.filter((blend) => {
        // Check if any of the excludedCoffees are keys with values > 0 in blendArray
        return !excludedCoffees.some((excludedCoffee) =>
            Object.keys(blend.blendArray).some(
                (key) => key === excludedCoffee && blend.blendArray[key] > 0
            )
        );
    });

 /*   // Check if the preset is in the array, if it exists, delete it
   const indexToDelete = filteredBlends.findIndex((blend) => {
    const hasSameBlendArray = Object.keys(preset.blendArray).every((key) => 
        preset.blendArray[key] === blend.blendArray[key]
    );

    const hasSameRoastLevel = blend.roastLevel === preset.roastLevel;

    // Log comparisons for debugging
    console.log('Checking blend:', blend);
    console.log('preset.blendArray:', preset.blendArray);
    console.log('blend.blendArray:', blend.blendArray);
    console.log('hasSameBlendArray:', hasSameBlendArray);
    console.log('preset.roastLevel:', preset.roastLevel);
    console.log('blend.roastLevel:', blend.roastLevel);
    console.log('hasSameRoastLevel:', hasSameRoastLevel);

    return hasSameBlendArray && hasSameRoastLevel;
});

if (indexToDelete !== -1) {
    console.log("index to delete: ", indexToDelete);
    console.log("filteredBlends before deletion:", filteredBlends);
    filteredBlends.splice(indexToDelete, 1);
    console.log("filteredBlends after deletion:", filteredBlends);
} */

    return filteredBlends.sort((a, b) => {
        if (a.matchPercentage > b.matchPercentage) return -1;
        if (a.matchPercentage < b.matchPercentage) return 1;

        return b.score - a.score;
    });
}

export function checkIfAbove85Percent(currentMatchPercentage, suggestedBlend) {
    if (currentMatchPercentage >= 85 && suggestedBlend) {
        return suggestedBlend.matchPercentage - currentMatchPercentage >= 2;
    } else if (currentMatchPercentage < 85 && suggestedBlend) {
        console.log("suggestied blend is:", suggestedBlend);
        return suggestedBlend.matchPercentage >= 85;
    }
    return false;
}

export const calculateBlendsByRoastingLevel = (
    allBlends,
    currentMatchPercentage,
    currentRoastingLevel
) => {
    let blends = allBlends.filter((blend) => {
        return blend.roastLevel === currentRoastingLevel;
    });

    let suggestedBlend = blends[0];

    return checkIfAbove85Percent(currentMatchPercentage, suggestedBlend)
        ? suggestedBlend
        : null;
};

export const chooseSingleCoffee = (
    blends,
    clientProfile,
    currentBlendMatchPercentage,
    currentSelectionsBlendArray
) => {
    let secondRule;
    const singleCoffeeChosen = Object.entries(currentSelectionsBlendArray)
        .filter(([key, value]) => value === 100)
        .reduce((obj, [key, value]) => {
            // Reconstruct the object with only the filtered entries
            obj[key] = value;
            return obj;
        }, {});

    let foundBlend = blends.find((blend) => {
        return (
            Object.values(blend.blendArray).some((value) => value === 100) &&
            JSON.stringify(blend.blendArray) ===
                JSON.stringify(currentSelectionsBlendArray)
        );
    });
    if (foundBlend) {
        secondRule = 1;
    }

    if (
        foundBlend &&
        !checkIfAbove85Percent(currentBlendMatchPercentage, foundBlend)
    ) {
        foundBlend = blends.find((blend) => {
            return Object.values(blend.blendArray).some((value) => value === 100);
        });
        secondRule = 2;

        if (!checkIfAbove85Percent(currentBlendMatchPercentage, foundBlend)) {
            foundBlend = blends.find((blend) => {
                // Directly use singleCoffeeChosen to access the property
                return blend.blendArray[singleCoffeeChosen] === 90;
            });
            secondRule = 3;
        }

        if (!checkIfAbove85Percent(currentBlendMatchPercentage, foundBlend)) {
            foundBlend = blends.find((blend) => {
                // Directly use singleCoffeeChosen to access the property
                return blend.blendArray[singleCoffeeChosen] === 80;
            });
            secondRule = 3;
        }
    }

    return [foundBlend, secondRule];
};

export function Choose4Coffees(blends, currentSelectionsBlendArray) {
    // בחירת הבלנדים שהלקוח כבר בחר
    let chosenSelections = Object.keys(currentSelectionsBlendArray).filter(
        (key) => {
            return currentSelectionsBlendArray[key] > 0;
        }
    );

    // בדיקה אם הלקוח בחר לפחות 4 סוגי קפה
    if (chosenSelections.length >= 4) {
        // נסיון למצוא בלנד מתאים עם אחוז התאמה מעל 90%
        let suggestedBlend = findSuitableBlend(blends, chosenSelections, 90);

        if (!suggestedBlend) {
            // אם לא נמצא בלנד מתאים מעל 90%, ניסיון למצוא בלנד עם אותם סוגי קפה למעט אחד, אבל עם התאמה מעל 90%
            suggestedBlend = findSuitableBlendWithOneLess(
                blends,
                chosenSelections,
                90
            );
        }

        if (!suggestedBlend) {
            // אם עדיין לא נמצא בלנד, ניסיון למצוא בלנד עם אחוז התאמה מעל 85%
            suggestedBlend = findSuitableBlend(blends, chosenSelections, 85);
        }

        if (!suggestedBlend) {
            // אם עדיין לא נמצא בלנד, ניסיון למצוא בלנד עם אותם סוגי קפה למעט אחד, אבל עם התאמה מעל 85%
            suggestedBlend = findSuitableBlendWithOneLess(
                blends,
                chosenSelections,
                85
            );
        }

        // החזרת הבלנד המתאים או null אם לא נמצא בלנד מתאים
        return suggestedBlend;
    }

    // אם הלקוח לא בחר מספיק סוגי קפה, לא להציע בלנד
    return null;
}


function findSuitableBlend(blends, selections, matchPercentage) {
    // חיפוש בלנד שכולל את כל הבחירות ועם אחוז התאמה לפחות כמו שהוגדר
    return blends.find((blend) => {
        let blendSelections = Object.keys(blend.blendArray).filter(
            (key) => blend.blendArray[key] > 0
        );
        let hasAllSelections = selections.every((selection) =>
            blendSelections.includes(selection)
        );
        let hasRequiredMatchPercentage = blend.matchPercentage >= matchPercentage;
        return hasAllSelections && hasRequiredMatchPercentage;
    });
}

function findSuitableBlendWithOneLess(blends, selections, matchPercentage) {
    // חיפוש בלנד שכולל את כל הבחירות למעט אחת ועם אחוז התאמה לפחות כמו שהוגדר
    for (let i = 0; i < selections.length; i++) {
        let modifiedSelections = selections.filter((_, index) => index !== i);
        let suitableBlend = blends.find((blend) => {
            let blendSelections = Object.keys(blend.blendArray).filter(
                (key) => blend.blendArray[key] > 0
            );
            let hasAllButOneSelections = modifiedSelections.every((selection) =>
                blendSelections.includes(selection)
            );
            let hasRequiredMatchPercentage =
                blend.matchPercentage >= matchPercentage;
            return hasAllButOneSelections && hasRequiredMatchPercentage;
        });

        if (suitableBlend) return suitableBlend;
    }

    // אם לא נמצא בלנד מתאים, החזר null
    return null;
}

export function ChooseNewCoffee(
    blends,
    targetProfile,
    currentBlendMatchPercentage,
    coffeeTypes,
    excludedCoffees,
    diffCoffeeKey,
    currentBlend
) {
    // Find a suitable blend based on the criteria
    return findSuitableBlendForNewCoffeeRule(
        blends,
        diffCoffeeKey,
        currentBlend,
        currentBlendMatchPercentage
    );
}

function findSuitableBlendForNewCoffeeRule(
    blends,
    diffCoffeeKey,
    currentBlend,
    matchPercentage
) {
    // First, try to find a blend with the diffCoffeeKey value being >= 50 if applicable
    let foundBlend = blends.find((blend) => {
        return currentBlend[diffCoffeeKey] >= 50
            ? blend.blendArray[diffCoffeeKey] >= 50
            : blend.blendArray[diffCoffeeKey] > 0;
    });

    // Check if the found blend has a match percentage above 85%
    if (foundBlend && !checkIfAbove85Percent(matchPercentage, foundBlend)) {
        return null; // If it doesn't meet the match percentage criteria, return null
    }

    return foundBlend; // Return the found blend or null if no suitable blend was found
}


export function isRoastLevelChanged(currentRoastinglevel, prevRoastinglevel) {
    return (
        currentRoastinglevel !== prevRoastinglevel
    );
}

export function isClientChooseSingleCoffee(currentSelectionsBlendArray) {
    return Object.values(currentSelectionsBlendArray).some(
        (value) => value === 100
    );
}

export let  isChoose4Coffees = (selections) => {
    return Object.values(selections).filter((value) => value > 0).length >= 4;
};

export const isChooseNewCoffee = (currentBlendArray, prevBlendArray) => {
    let diffKey = false;

    Object.keys(prevBlendArray).forEach((key) => {
        if (prevBlendArray[key] === 0 && currentBlendArray[key] > 0) {
            diffKey = key;
        }
    });
    return diffKey;
};



export const isChanged = (
    currentSelections,
    prevSelections,
    excludedCoffees,
    prevExcludedCoffees,
    isChangedByClient
) => {
    let total = Object.values(currentSelections.blendArray).reduce(
        (a, b) => a + b,
        0
    );

    const difference = Math.abs(currentSelections.roastLevel - prevSelections.current.roastLevel);
    console.log("diffrence is: ",difference)
    

    return (
        isChangedByClient &&
        total === 100 &&
        (JSON.stringify(currentSelections.blendArray) !==
            JSON.stringify(prevSelections.current.blendArray) ||
            difference === 1 || difference === 2 ||
            JSON.stringify(excludedCoffees) !==
                JSON.stringify(prevExcludedCoffees))
    );
};