import React, { useEffect, useRef, useState } from "react";
import {
	calculateBlendProfile,
	calculateBlendsByProfile,
	calculateMatchPercentage,
	coffeeTypes,
} from "../utils/functionsUtils";

import {
	calculateBlendsSortAndExclude,
	calculateBlendsByRoastingLevel,
	chooseSingleCoffee,
	Choose4Coffees,
	ChooseNewCoffee,
	isRoastLevelChanged,
	isClientChooseSingleCoffee,
	isChoose4Coffees,
	isChooseNewCoffee,
	isChanged
} from "../utils/hananFunctions";

import {
	calculateTasteNotes,
} from "../utils/tasteNotesFunctions";

import { generateOptions } from "../utils/editorUtils";
import { useLocation } from "react-router-dom";
import ProfileCard from "../UI/ProfileCard";
import { SuggestionCard } from "../UI/SuggestionCard";
import RangeSlider from 'react-range-slider-input';
import 'react-range-slider-input/dist/style.css';



const Editor = () => {
	let location = useLocation();
	let { bestBlends = [], targetProfile } = location.state || {};
	console.log("presets: ", bestBlends)
	const [isChangedByClient, setIsChangedByClient] = useState(true);
	const [totalPercentage, setTotalPercentage] = useState(0);
	
	const [currentMatchPercentage, setCurrentMatchPercentage] = useState();
	const [currentProfilePreferences, setCurrentProfilePreferences] = useState();
	const [currentTasteNotes, setCurrentTasteNotes] = useState({});


	const initialSelections = bestBlends[0] || {
		blendArray: {
			ethiopia: 0,
			brazil: 0,
			colombia: 0,
			honduras: 0,
			papua: 0,
			robusta: 0,
		},
		roastLevel: 1,
		tasteNotes: [],
		matchPercentage: 0,
		rulesMet: [],
	};

	const [currentSelections, setCurrentSelections] = useState(initialSelections);
	const [currentSuggestions, setCurrentSuggestions] = useState([]);
	const prevSelectionsRef = useRef({
		blendArray: currentSelections.blendArray,
		roastLevel: currentSelections.roastLevel,
	});
	const [excludedCoffees, setExcludedCoffees] = useState([]);
	const prevExcludedCoffeesRef = useRef(excludedCoffees);

	useEffect(() => {
		const total = Object.values(currentSelections.blendArray).reduce(
			(acc, value) => acc + Number(value),
			0
		);
		setTotalPercentage(total);
		checkChangesAndOfferSuggestions();
	}, [currentSelections]);

	const handleSelectionChange = (e, key) => {
		setIsChangedByClient(true)
		const value = Number(e.target.value);
		setCurrentSelections((prev) => ({
			...prev,
			blendArray: {
				...prev.blendArray,
				[key]: value,
			},
		}));
	};

	const handleRoastChange = (e) => {
		setIsChangedByClient(true)
		const value = Math.round(Number(e[1]));
		 console.log("value:",value);
		 const difference = Math.abs(value - currentSelections.roastLevel);
		 if(difference === 1 || difference === 2){
			setCurrentSelections((prevState) => ({ ...prevState, roastLevel: value }));
		 }
	};

	const handleExclusionChange = (e) => {
		setIsChangedByClient(true)
		const { value, checked } = e.target;
		setExcludedCoffees((current) => {
			if (checked) {
				return [...current, value];
			} else {
				return current.filter((type) => type !== value);
			}
		});

		setCurrentSelections((prev) => ({
			...prev,
			blendArray: {
				...prev.blendArray,
				[value]: 0,
			},
		}));
	};

	useEffect(() => {
		checkChangesAndOfferSuggestions();
	}, [excludedCoffees]);

	const checkChangesAndOfferSuggestions = () => {
		if (isChanged(
			currentSelections, 
			prevSelectionsRef, 
			excludedCoffees,
			prevExcludedCoffeesRef,
			isChangedByClient)) {
			let newSuggestions = [];

			//calculate all possible blends
			let allBlends = calculateBlendsSortAndExclude(
				targetProfile,
				coffeeTypes,
				excludedCoffees,
				bestBlends[0]
			);

			//find the coffee table by the roasting level
			let currentCoffeeTypeByRoastLevel = coffeeTypes.find(
				(type) => type.roastLevel === currentSelections.roastLevel
			);

			// The current blend preferences
			let blendProfile = calculateBlendProfile(
				currentSelections.blendArray,
				currentCoffeeTypeByRoastLevel
			);
			setCurrentProfilePreferences((prev) => ({
				...prev,
				blendProfile,
			}));

			//The current blend match percentage
			let currentBlendMatchPercentage = calculateMatchPercentage(
				blendProfile,
				targetProfile
			);
			setCurrentMatchPercentage(currentBlendMatchPercentage);

			//The current blend Taste notes by the rules
			let currentTasteNotes = calculateTasteNotes(
				currentCoffeeTypeByRoastLevel,
				currentSelections,
				targetProfile.tasteNotes
			);
			setCurrentTasteNotes(currentTasteNotes)

			// The client changed the roasting level
			if (isRoastLevelChanged(currentSelections.roastLevel,
									prevSelectionsRef.current.roastLevel)) {
				newSuggestions.push({
					rule: "RoastLevelChanged",
					blend: calculateBlendsByRoastingLevel(
						allBlends,
						currentBlendMatchPercentage,
						currentSelections.roastLevel
					),
				});
			}

			// The client choose single coffee type
			if (isClientChooseSingleCoffee(currentSelections.blendArray)) {
				let foundBlend = chooseSingleCoffee(
					allBlends,
					targetProfile,
					currentBlendMatchPercentage,
					currentSelections.blendArray
				);
				newSuggestions.push({
					rule: "singleCoffee",
					secondRule: foundBlend[1],
					blend: foundBlend[0],
				});
			}

			// The client choose at least 4 kinds of coffee types
			if (isChoose4Coffees(currentSelections.blendArray)) {
				newSuggestions.push({
					rule: "choose4Coffees",
					blend: Choose4Coffees(allBlends, currentSelections.blendArray),
				});
			}

			// The client choose new type of coffee
			let diffCoffeeKey = isChooseNewCoffee(
				currentSelections.blendArray,
				prevSelectionsRef.current.blendArray
			);
			if (diffCoffeeKey) {
				newSuggestions.push({
					rule: "chooseNewCoffee",
					blend: ChooseNewCoffee(
						allBlends,
						targetProfile,
						currentBlendMatchPercentage,
						coffeeTypes,
						excludedCoffees,
						diffCoffeeKey,
						currentSelections.blendArray,
						prevSelectionsRef.current.blendArray
					),
				});
			}
			updateStatesAndSuggestions(newSuggestions);
		}
	};


	function updateStatesAndSuggestions(newSuggestions) {
		const ruleOrder = [
			"singleCoffee",
			"choose4Coffees",
			"RoastLevelChanged",
			"chooseNewCoffee",
		];

		if (newSuggestions.length > 1) {
			newSuggestions.sort((a, b) => {
				const indexA = ruleOrder.indexOf(a.rule);
				const indexB = ruleOrder.indexOf(b.rule);

				// Check if one of the rules is Rule 3 and the other is Rule 5
				if (
					(a.rule === "RoastLevelChanged" && b.rule === "chooseNewCoffee") ||
					(a.rule === "chooseNewCoffee" && b.rule === "RoastLevelChanged")
				) {
					if (a.blend != null && b.blend != null) {
						// Access matchPercentage inside blend attribute
						const isARule5 = a.rule === "chooseNewCoffee";
						const diff = isARule5
							? a.blend.matchPercentage - b.blend.matchPercentage
							: b.blend.matchPercentage - a.blend.matchPercentage;

						// If Rule 5's matchPercentage is more than 5% greater, prioritize Rule 5
						if (diff > 5) {
							return isARule5 ? -1 : 1;
						}
					}
				}

				return indexA - indexB;
			});
			//console.log("new suggestions updated:", newSuggestions)
		}
		// For a single suggestion or sorted suggestions, update state
		// This will update with all unique blends if multiple, or just the one if a single
		setCurrentSuggestions(() => {
			console.log("allSuggestions,", Array.from(new Set(newSuggestions)));
			let finalSuggestions = Array.from(
				newSuggestions.filter((suggestion) => suggestion.blend != null)
			);
			console.log("finalSuggestionsAfterFilter:", finalSuggestions);
			//const uniqueBlends = Array.from(new Set(newSuggestions.map(suggestion => suggestion.blend)));
			//finalSuggestions = Array.from(new Set(newSuggestions));
			return finalSuggestions.length > 1
				? [finalSuggestions[0]]
				: finalSuggestions;
		});

		// Update the previous selections ref with the current selections for the next comparison
		prevSelectionsRef.current = {
			blendArray: currentSelections.blendArray,
			roastLevel: currentSelections.roastLevel,
		};

		prevExcludedCoffeesRef.current = excludedCoffees;
	}


	return (
		<>

			<div className="max-w-7xl mx-auto mt-8 flex flex-wrap justify-between space-x-4">
				{/* Right Column for Coffee Selections */}
				<div className="flex-1">
					<div className="flex flex-col justify-center p-8 bg-white shadow-lg rounded-lg">
						<p className="text-base font-semibold mb-4 text-gray-700">
							Current Match Percentage:{" "}
							<span className="font-normal text-indigo-600">
								{currentMatchPercentage}%
							</span>
						</p>
						<p className="text-base font-semibold mb-4 text-gray-700">
						<table style={{ borderCollapse: 'collapse', width: '100%' }}>
            <thead>
                <tr>
                    <th style={{ border: '1px solid black', padding: '8px' }}>Category</th>
                    <th style={{ border: '1px solid black', padding: '8px' }}>Notes</th>
                </tr>
            </thead>
            <tbody>
                {Object.keys(currentTasteNotes).map(category => (
                    <tr key={category}>
                        <td style={{ border: '1px solid black', padding: '8px' }}>{category}</td>
                        <td style={{ border: '1px solid black', padding: '8px' }}>
                            <ul style={{ listStyleType: 'none', padding: 0, margin: 0 }}>
                                {currentTasteNotes[category].map(note => (
                                    <li key={note}>{note}</li>
                                ))}
                            </ul>
                        </td>
                    </tr>
                ))}
            </tbody>
        </table>
						</p>

						{Object.keys(currentSelections.blendArray).map((key, index) => (
							<div
								key={index}
								className="flex items-center mb-5"
							>
								<label
									htmlFor={key}
									className="w-1/4 mr-4 text-sm font-semibold text-gray-600"
								>
									{key}:
								</label>
								<select
									id={key}
									className="block w-3/5 mt-1 border-gray-300 rounded-md shadow-sm focus:border-indigo-500 focus:ring focus:ring-indigo-200 focus:ring-opacity-50"
									value={currentSelections.blendArray[key]}
									onChange={(e) => handleSelectionChange(e, key)}
								>
									{generateOptions(
										key,
										totalPercentage,
										currentSelections,
										excludedCoffees
									)}
								</select>
								<label className="ml-4 text-sm font-medium text-gray-600">
									Exclude
								</label>
								<input
									type="checkbox"
									className="ml-2 w-4 h-4 text-indigo-600 border-gray-300 rounded focus:ring-indigo-500"
									id={key + "Exclude"} // Updated ID for accessibility
									value={key}
									onChange={(e) => handleExclusionChange(e)}
									checked={excludedCoffees.includes(key)}
								/>
							</div>
						))}
						{/* Render roast level option */}
						<div className="flex items-center mb-4">
							<label className="w-1/2 mr-4 text-sm font-semibold text-gray-600">
								Roasting Level:
							</label>
							{/* <select
								value={currentSelections.roastLevel}
								onChange={handleRoastChange}
								className="block w-3/5 mt-1 border-gray-300 rounded-md shadow-sm focus:border-indigo-500 focus:ring focus:ring-indigo-200 focus:ring-opacity-50"
							>
								{[1, 2, 3].map((level) => (
									<option
										key={level}
										value={level}
									>
										{level}
									</option>
								))}
							</select> */}
							<RangeSlider
							className="roasting_level"
							min={1}
							max={3}
							step={0.01}
							thumbsDisabled={[true, false]}
							rangeSlideDisabled={true}
							defaultValue={[1, currentSelections.roastLevel]}
							onInput={handleRoastChange}
							/>
						</div>
					</div>
				</div>

				<div className="flex-1">
					{/* Profile Card */}
					<div>
						{" "}
						{/* Adjust margin as needed */}
						<ProfileCard profile={targetProfile} />
				
					</div>

					<div className="mt-4">
						{currentSuggestions.filter((suggestion) => suggestion.blend != null)
							.length > 0 &&
							currentSuggestions
								.filter((suggestion) => suggestion.blend != null)
								.map((suggestion, index) => (
									<SuggestionCard
										key={index}
										secondRule={suggestion.secondRule}
										rule={suggestion.rule}
										blend={suggestion.blend}
										index={index}
										className="mb-4 last:mb-0"
									/>
								))}
					</div>
				</div>
			</div>
		</>
	);
};

export default Editor;
