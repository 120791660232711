export const calculateTasteNotes = (
    currentCoffeeTypeByRoastLevel,
    currentSelections,
    profileTasteNotesArray
) => {
    // Function to refactor the blend selections after the factors oriel gave us
    let selectionsAfterFactors = adjustSelectionsByFactors(currentSelections);

    // Function to take the parent taste and children taste notes according to Oriel's rules
    return selectTasteNotesByRules(
        currentCoffeeTypeByRoastLevel,
        selectionsAfterFactors,
        profileTasteNotesArray
    );
};


export const adjustSelectionsByFactors = (currentSelections) => {
    let blendArray = { ...currentSelections.blendArray };
    if (blendArray.brazil > 0) blendArray.brazil -= 10;
    if (blendArray.papua > 0) blendArray.papua -= 10;
    if (blendArray.colombia > 0) blendArray.colombia += 10;
    if (blendArray.ethiopia > 0) blendArray.ethiopia += 10;

    return blendArray;
};


export const selectTasteNotesByRules = (
    currentCoffeeTypeByRoastLevel,
    selectionsAfterFactors,
    profileTasteNotesArray
) => {
    let tasteNotes = {};
    let selectedNotes = new Set();
    let selectedFamilies = new Set();
    const clientFamilies = new Set(profileTasteNotesArray.map(note => note.family));
    console.log("clientFamilies: ",clientFamilies)

    const sortedSelections = Object.entries(selectionsAfterFactors).sort(([, a], [, b]) => b - a);

    const addTasteNote = (coffeeType, availableFamilies) => {
        for (let family of availableFamilies) {
            const notes = currentCoffeeTypeByRoastLevel[coffeeType].tasteNotes[family];
            if (notes) {
                for (let note of notes) {
                    if (note && !selectedNotes.has(note)) {
                        selectedNotes.add(note);
                        if (!tasteNotes[family]) {
                            tasteNotes[family] = [];
                        }
                        tasteNotes[family].push(note);
                        selectedFamilies.add(family);
                        return true; // Note successfully added
                    }
                }
            }
        }

        const tasteNotesObj = currentCoffeeTypeByRoastLevel[coffeeType].tasteNotes;
        for (let family in tasteNotesObj) {
            if (tasteNotesObj.hasOwnProperty(family)) {
                const notes = tasteNotesObj[family];
                if (notes) {
                    for (let note of notes) {
                        if (note && !selectedNotes.has(note)) {
                            selectedNotes.add(note);
                            if (!tasteNotes[family]) {
                                tasteNotes[family] = [];
                            }
                            tasteNotes[family].push(note);
                            selectedFamilies.add(family);
                            return true; // Note successfully added
                        }
                    }
                }
            }
        }
        return false; // No note was added
    };
    
    
    // First pass: Select taste notes only from the client's preferred families
const selectTasteNotes = () => {
    for (let [coffeeType, percentage] of sortedSelections) {
        while (percentage >= 30 && selectedNotes.size < 4) {

            const availableFamilies = Array.from(profileTasteNotesArray).filter(family =>
                currentCoffeeTypeByRoastLevel[coffeeType] && currentCoffeeTypeByRoastLevel[coffeeType].tasteNotes[family]
            );

            if (addTasteNote(coffeeType, availableFamilies )){
                    percentage -= 30;
                     const index = sortedSelections.findIndex(([k, v]) => k === coffeeType);
                    if (index !== -1) {
                        sortedSelections[index][1] -= 30;
                    } 
            } else{
                break;
            }
        }
    }
};

    // Check for Robusta and add a taste note if present
    if (selectionsAfterFactors.robusta > 0) {
        selectRobustaTasteNote(
            currentCoffeeTypeByRoastLevel.robusta.tasteNotes,
            tasteNotes,
            selectedFamilies,
            selectedNotes
        );
    }

    selectTasteNotes();
    
    if (selectedNotes.size < 4) {
        for (let [coffeeType, percentage] of sortedSelections) {
            if (percentage > 0 && selectedNotes.size < 4) {
                const availableFamilies = Array.from(profileTasteNotesArray).filter(family =>
                    currentCoffeeTypeByRoastLevel[coffeeType] && currentCoffeeTypeByRoastLevel[coffeeType].tasteNotes[family]
                );
                
                addTasteNote(coffeeType, availableFamilies)
                
            }
        }
    }
    
    return tasteNotes;
};

export const selectRobustaTasteNote = (robustaTasteNotes, tasteNotes, selectedFamilies, selectedNotes) => {
    let robustaFamilies = Object.keys(robustaTasteNotes);
    let randomFamily = robustaFamilies[Math.floor(Math.random() * robustaFamilies.length)];
    let randomTaste = robustaTasteNotes[randomFamily][Math.floor(Math.random() * robustaTasteNotes[randomFamily].length)];

    if (randomTaste && !selectedNotes.has(randomTaste)) {
        if (!tasteNotes[randomFamily]) {
            tasteNotes[randomFamily] = [];
        }
        tasteNotes[randomFamily].push(randomTaste);
        selectedNotes.add(randomTaste);
        selectedFamilies.add(randomFamily);
    }
};
